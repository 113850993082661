@mixin display-flex($align: center, $justify: center) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin hover($transition: all 0.2s ease) {
  cursor: pointer;
  transition: $transition;
}

@mixin container($max-width: 1280px, $margin: 0 auto) {
  max-width: $max-width;
  margin: $margin;
}
