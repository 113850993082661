$color-primary: #000040;
$color-secondary: rgba(100, 149, 237, 0.1);
$color-snow: #fff;
$color-darkHigh: #0a0909;
$color-grayMedium: #929e99;
$color-grayLight: #f3f4f9;
$color-error: #d6103b;
$color-success: RGB(0, 215, 87);
$color-yellow: #ffcc29;

$font-small: 0.9rem;
$font-default: 1.2rem;
$font-medium: 2rem;
$font-large: 3rem;

$weight-light: 400;
$weight-default: 500;
$weight-medium: 600;
$weight-bold: 700;

$transition-default: all 0.3s ease;

$radii-small: 0.6rem;
$radii-default: 0.9rem;
$radii-large: 1.5rem;
