@import '../../styles/themes/light.scss';
@import '../../styles/themes/mixins.scss';

.container {
  @include container(1180px);
  @include display-flex(flex-start, flex-start);
  flex-direction: column;
  padding: 10.5rem 2rem 6.5rem;
  .title {
    font-size: $font-large;
    font-weight: $weight-medium;
    line-height: 2.9rem;
    margin-bottom: 1.4rem;
    @media (max-width: 880px) {
      font-size: $font-small;
      font-weight: $weight-bold;
      line-height: 2rem;
    }
  }
  .subtitle {
    font-size: $font-default;
    font-weight: $weight-medium;
    line-height: 1.5rem;
    margin-bottom: 1.2rem;
    @media (max-width: 880px) {
      font-size: $font-small;
      line-height: 1.3rem;
    }
  }
  .description {
    font-size: $font-default;
    font-weight: $weight-medium;
    line-height: 1.5rem;
    margin-bottom: 1.8rem;
    @media (max-width: 880px) {
      font-size: $font-small;
      line-height: 1.3rem;
    }
  }
}
