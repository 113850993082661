@import '../../styles/themes/light.scss';
@import '../../styles/themes/mixins.scss';

.container {
  @include display-flex(center, space-around);
  background-color: $color-primary;
  padding: 0 2rem;
  height: 100%;
  @media (max-width: 1080px) {
    @include display-flex(center, center);
    padding: 2rem 1rem;
  }
  .logo {
    background-color: $color-primary;
    width: 37.25rem;
    margin-right: 2rem;
    @media (max-width: 1080px) {
      display: none;
    }
  }
  .form {
    @include display-flex;
    flex-direction: column;
    box-shadow: rgba(100, 100, 111, 0.2) 0 0.4375rem 1.015rem 0;
    border: 1px solid $color-primary;
    background-color: $color-snow;
    border-radius: $radii-default;
    width: 40.625rem;
    padding: 2.075rem 2.575rem;
    @media (max-width: 880px) {
      padding: 2rem 1.5rrem;
    }
    @media (max-width: 880px) {
      width: 90%;
      padding: 2rem 2.5rem;
    }
    .box-title {
      @include display-flex(flex-start, flex-start);
      flex-direction: column;
      gap: 2rem;
      @media (max-width: 880px) {
        flex-direction: column;
        gap: 1rem;
      }
      .text-america {
        font-weight: $weight-default;
        margin-bottom: 0.2rem;
      }
      .title {
        color: $color-darkHigh;
        font-size: $font-medium;
        font-weight: $weight-bold;
        line-height: 1.9rem;
        margin-bottom: 0.4rem;
        @media (max-width: 994px) {
          font-size: $font-default;
        }
      }
      .subtitle {
        font-weight: $weight-default;
      }
    }

    .input-text {
      border-bottom: 0.0625rem solid $color-grayMedium;
      transition: border-bottom 0.3s ease;
      color: $color-darkHigh;
      font-size: $font-default;
      font-weight: $weight-medium;
      margin-top: 1.2rem;
      padding: 0.4375rem 0;
      width: 100%;
      @media (max-width: 880px) {
        font-size: $font-small;
      }
      &:focus {
        border-bottom: 10.0625remx solid $color-darkHigh;
      }
    }
    .terms-box {
      @include display-flex(center, flex-start);
      .checkbox {
        border: 0.125rem solid $color-darkHigh;
        margin: 1.5rem 0;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: $radii-small;
        &:hover {
          cursor: pointer;
        }
      }
      .label-checkbox {
        position: relative;
        user-select: none;
        color: $color-darkHigh;
        font-weight: $weight-bold;
        font-size: $font-small;
        padding-left: 0.5rem;
      }
    }
    .error {
      color: $color-error;
      font-weight: $weight-default;
      font-size: $font-small;
    }
    .checkbox-error + .label-checkbox {
      color: $color-error;
    }
    .input-text-error {
      border-color: $color-error;
      &:focus {
        border-color: $color-error;
      }
    }
  }
}
