@import '../../styles/themes/light.scss';
@import '../../styles/themes/mixins.scss';

.header {
  .box {
    position: relative;
    .img {
      width: 100%;
      height: 325px;
      display: block;
      margin-bottom: 0.7rem;
      object-fit: cover;
    }
    .title {
      position: absolute;
      top: 50%;
      left: 20%;
      text-transform: uppercase;
      color: $color-snow;
      letter-spacing: 0.1px;
      font-size: $font-large;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      @media (max-width: 55rem) {
        font-size: $font-medium;
      }
    }
  }
}

.icons-section {
  @include container(1080px);
  @include display-flex(center, space-around);
  padding: 0 2rem;
  margin-top: 2.5rem;
  gap: 2.5rem;
  @media (max-width: 55rem) {
    flex-direction: column;
  }
  .icons-box {
    text-align: center;
    .img {
      width: auto;
      height: 170px;
    }
    .title-icon {
      font-size: 1.6rem;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }
  }
}

.links-section {
  @include container(1180px);
  @include display-flex(flex-start, space-around);
  padding: 0 2rem;
  margin-top: 5rem;
  margin-bottom: 6rem;
  @media (max-width: 55rem) {
    @include display-flex(center, space-around);
    flex-direction: column;
  }
  .links-box {
    text-align: center;
    @media (max-width: 55rem) {
      margin-bottom: 3rem;
    }
    .img {
      width: auto;
      height: 80px;
    }
    .title-icon {
      font-size: 1.6rem;
      margin-top: 0.5rem;
      margin-bottom: 0.25rem;
    }
    .clickable {
      text-transform: uppercase;
      font-weight: $weight-light;
      cursor: pointer;
    }
    ul {
      margin-top: 0.5rem;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.06);
      border-radius: $radii-default;
      padding: 1rem 2rem;
      li {
        a {
          color: $color-primary;
          font-size: $font-default;
          border-bottom: 1px solid $color-primary;
        }
      }
    }
  }
}
