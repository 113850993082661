@import '../../styles/themes/light.scss';
@import '../../styles/themes/mixins.scss';

.mission-container {
  background-color: $color-secondary;
  .mission-section {
    @include display-flex;
    @include container;
    padding: 0 2rem;
    gap: 2rem;
    @media (max-width: 923px) {
      flex-direction: column;
      padding: 2rem;
    }
    @media (min-width: 924px) and (max-width: 1124px) {
      flex-wrap: wrap;
      padding: 2rem;
    }
    .mission-card {
      border: 0.5vmin solid $color-secondary;
      box-shadow: 0.1rem 0.1rem $color-primary;
      color: $color-darkHigh;
      border-radius: $radii-default;
      padding: 2rem;
      height: 250px;
      width: 380px;
      .title {
        margin-bottom: 0.1em;
        font-size: 1.7rem;
      }
      .subtitle {
        margin-top: 0.3rem;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.3rem;
        @media (max-width: 825px) {
          font-size: 1.1rem;
        }
      }
    }
  }
}

.container {
  background-color: $color-secondary;
  padding: 7rem 0;
  @media (max-width: 924px) {
    border-radius: 0% 0;
    padding: 5rem 0;
  }
  .box-section {
    @include container;
    position: relative;
    &:before {
      position: absolute;
      display: block;
      margin: 0 auto;
      content: '';
      background: $color-primary;
      right: 1.25rem;
      width: 0.125rem;
      height: 100%;
      top: 0;
      left: 0;
    }
    .timeline {
      position: relative;
      padding: 0 2rem;
      margin-bottom: 2.5rem;
      &:after {
        display: block;
        clear: both;
        content: '';
      }
      &:nth-child(2n) {
        .date-content {
          float: right;
          &:before {
            left: 0.625rem;
          }
        }
        .timeline-content {
          text-align: right;
          padding: 1.25rem 3.125rem 1.25rem 0;
        }
      }
      &:hover {
        .icon:before {
          left: 0.1875rem;
        }
        .icon:after {
          left: -0.1875rem;
        }
        .date-outer:before {
          left: 0.375rem;
        }
        .date-outer:after {
          left: -0.375rem;
        }
      }
    }
    .icon {
      margin: auto;
      position: absolute;
      width: 1.125rem;
      height: 1.125rem;
      line-height: 1.125rem;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      &:before,
      &:after {
        position: absolute;
        content: '';
        transition: all 0.33s ease-out;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        top: 0;
        left: 0;
      }
      &:before {
        background: $color-snow;
        border: 0.125rem solid $color-primary;
        left: -0.2rem;
      }
      &:after {
        border: 0.125rem solid $color-primary;
        left: 0.2rem;
      }
    }
    .date-content {
      position: relative;
      float: left;
      width: 50%;
      margin-top: 1.4rem;
      &:before {
        position: absolute;
        margin: auto 0;
        content: '';
        background: $color-primary;
        width: 36.5%;
        height: 0.1rem;
        right: 0.6rem;
        top: 0;
        bottom: 0;
      }
    }
    .date-outer {
      margin: auto;
      text-align: center;
      font-size: $font-default;
      width: 7.8rem;
      height: 7.8rem;
      z-index: 1;
      &:before,
      &:after {
        position: absolute;
        margin: 0 auto;
        transition: all 0.33s ease-out;
        content: '';
        width: 7.8rem;
        height: 7.8rem;
        border-radius: 50%;
        top: 0;
        left: 0;
        right: 0;
      }
      &:before {
        background: $color-snow;
        border: 2px solid $color-primary;
        left: -0.4rem;
      }
      &:after {
        border: 2px solid $color-grayLight;
        left: 0.4rem;
      }
    }
    .date {
      margin: auto;
      position: absolute;
      width: 100%;
      top: 27%;
      left: 0;
      .duration {
        color: $color-primary;
        font-size: $font-default;
        font-weight: $weight-bold;
      }
      .year {
        display: block;
        color: $color-primary;
        font-size: $font-medium;
        font-weight: $weight-bold;
        line-height: 2.25rem;
      }
    }
    .timeline-content {
      float: right;
      width: 50%;
      padding: 1.25rem 0 1.25rem 3.125rem;
      .title {
        color: $color-primary;
        font-size: $font-medium;
        font-weight: $weight-medium;
        line-height: 1.9rem;
        margin-bottom: 0.8rem;
        @media (max-width: 924px) {
          font-size: $font-default;
          font-weight: $weight-medium;
          margin-bottom: 0.5rem;
        }
      }
      .description {
        font-size: $font-default;
        line-height: 1.4rem;
        margin-bottom: 0;
        @media (max-width: 924px) {
          font-size: $font-small;
          font-weight: $weight-medium;
          line-height: 1.1rem;
        }
      }
    }
    @media only screen and (max-width: 991px) {
      .date-content {
        margin-top: 2.1rem;
        &:before {
          width: 22.5%;
        }
      }
      .timeline-content {
        padding: 0.625rem 0 0.625rem 1.875rem;
      }
      .title {
        font-size: 1rem;
      }
      .timeline:nth-child(2n) .timeline-content {
        padding: 0.625rem 1.875rem 0.625rem 0;
      }
    }
    @media only screen and (max-width: 767px) {
      &:before {
        margin: 0;
        left: 0.4rem;
      }
      .timeline {
        margin-bottom: 1.25rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .icon {
        margin: auto 0;
      }
      .date-content {
        float: right;
        width: 95%;
        margin-top: 0;
        &:before {
          display: none;
        }
      }
      .date-outer {
        width: 6.8rem;
        height: 6.8rem;
        &:before,
        &:after {
          width: 6.8rem;
          height: 6.8rem;
        }
      }
      .date {
        top: 30%;

        .year {
          font-size: 1.5rem;
        }
      }
      .timeline-content,
      .timeline:nth-child(2n) .timeline-content {
        text-align: center;
        width: 95%;
        padding: 0.6rem 0;
      }
      .title {
        margin-bottom: 0.6rem;
      }
    }
  }
}
