@import '../../styles/themes/light.scss';
@import '../../styles/themes/mixins.scss';

.container {
  @include container(1280px, 0 auto);
  @include display-flex;
  flex-direction: column;
  background-color: $color-snow;
  color: $color-darkHigh;
  border-radius: $radii-default;
  margin-bottom: 3.5rem;
  padding: 0 2rem;
  @media (max-width: 880px) {
    margin-bottom: 2rem;
    width: 100%;
  }
  .logo {
    width: 200px !important;
    height: 50px;
    margin-bottom: 0.4rem;
    @media (max-width: 880px) {
      margin-bottom: 0.8rem;
      width: 150px !important;
      height: 50px;
    }
  }
  .title {
    font-size: $font-large;
    font-weight: $weight-medium;
    margin-bottom: 1rem;
    @media (max-width: 880px) {
      font-size: $font-medium;
    }
  }
  .subtitle {
    font-size: $font-large;
    font-weight: $weight-medium;
    margin-bottom: 0.8rem;
    @media (max-width: 880px) {
      font-size: $font-medium;
      line-height: 1.5rem;
      margin-bottom: 0.8rem;
    }
  }
  .paragraph {
    @include container(980px, 0 auto);
    text-align: center;
    font-size: $font-default;
    font-weight: $weight-default;
    line-height: 1.2rem;
    margin-bottom: 2rem;
    @media (max-width: 880px) {
      font-size: $font-small;
      line-height: 1rem;
      margin-bottom: 1.4rem;
    }
  }
  .icons-section {
    @include display-flex;
    margin: 1rem 0 2rem 0;
    gap: 2rem;
    .icon {
      color: $color-primary;
      font-size: 3.8rem;
      border-radius: 50%;
      @media (max-width: 880px) {
        font-size: 2.8rem;
      }
    }
  }
}
