@import '../../styles/themes/light.scss';
@import '../../styles/themes/mixins.scss';

.container {
  background-color: #f8f8f8;
  font-family: 'Inter', sans-serif;
  .section-differentials {
    @include container;
    padding-top: 112px;
    padding: 7rem 2rem 0 2rem;
    .subtitle {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: $weight-medium;
      color: #f9c536;
      font-family: 'Inter', ans-serif;
      @media (max-width: 1044px) {
        text-align: center;
      }
    }
    .title {
      margin-top: 0.25rem;
      font-size: 24px;
      font-weight: $weight-medium;
      font-family: 'Inter', sans-serif;
      @media (max-width: 1044px) {
        text-align: center;
      }
    }
    .grid {
      @include display-flex;
      margin-top: 42px;
      gap: 2rem;
      @media (max-width: 1044px) {
        flex-wrap: wrap;
      }
      .card {
        display: flex;
        flex-direction: column;
        border-radius: $radii-small;
        padding: 2rem;
        width: 18.75rem;
        height: 14rem;
        gap: 0.5rem;
        box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1), 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.06);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        &:hover {
          transform: translateY(-8px);
        }
        .icon-wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .box-icon {
            display: inline-block;
            border: 1px solid;
            border-radius: 50%;
            margin-bottom: 0.5rem;
            padding: 0.5rem 0.5rem 0.2rem 0.5rem;
          }
        }
        .card-title {
          font-size: 24px;
          font-weight: $weight-medium;
          line-height: 1.9375rem;
        }
        .card-subtitle {
          font-weight: $weight-light;
          font-size: 17.6px;
          line-height: 1.5rem;
        }
      }
    }
  }

  .section-stats {
    @include container;
    padding-top: 112px;
    margin-bottom: 6.5rem;
    padding: 4rem 2rem 0 2rem;
    .button-container {
      @include display-flex;
      margin-top: 4rem;
      .button-section {
        @include display-flex;
        @include hover;
        display: block;
        line-height: 20px;
        font-weight: 700;
        border-radius: 5px;
        width: 200px;
        padding: 0.8rem 1.4rem;
        font-family: 'Inter', sans-serif;
        background-color: $color-success;
        color: $color-snow;
        .link {
          color: inherit;
        }
        @media (max-width: 1124px) {
          display: none;
        }
        .icon {
          font-size: 1.75rem;
          margin-left: 0.75rem;
        }
        @media (min-width: 1024px) {
          display: flex;
        }
      }
    }
    .title {
      margin-top: 20px;
      line-height: 32px;
      margin-left: 5px;
      font-size: 24px;
      font-weight: $weight-medium;
      font-family: 'Inter', sans-serif;
      @media (max-width: 1044px) {
        text-align: center;
      }
    }
    .grid {
      @include display-flex;
      margin-top: 32px;
      gap: 2rem;
      @media (max-width: 1044px) {
        flex-wrap: wrap;
      }
      .card {
        @include hover;
        display: flex;
        flex-direction: column;
        border-radius: $radii-small;
        padding: 2rem;
        width: 18.75rem;
        height: 14.1rem;
        gap: 0.5rem;
        box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1), 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.06);
        &:hover {
          transform: translateY(-8px);
        }
        @media (max-width: 1114px) and (min-width: 1045px) {
          height: 16rem;
        }
        .icon-wrapper {
          padding: 0.25rem;
        }
        .card-title {
          font-size: 40px;
          line-height: 64px;
          font-weight: $weight-medium;
          font-family: 'Inter', sans-serif;
          @media (max-width: 1280px) and (min-width: 1045px) {
            line-height: 48px;
          }
        }
        .card-subtitle {
          font-size: 16px;
          line-height: 24px;
          font-family: 'Inter', sans-serif;
          font-weight: $weight-light;
        }
      }
    }
  }
}

.whatsapp-button {
  @include display-flex;
  background-color: $color-success;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0.25rem 0.75rem;
  transition: transform 0.3s;
  position: fixed;
  border-radius: 20px;
  bottom: 1.25rem;
  right: 1.2rem;
  width: 3.5rem;
  height: 3.5rem;
  z-index: 999;
  &:hover {
    transform: scale(1.1);
  }
  &::before,
  &::after {
    background-color: rgba(37, 211, 102, 0.5);
    position: absolute;
    animation: wave-animation 2s infinite ease-out;
    content: '';
    transform: scale(1);
    border-radius: 20px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
  }
  &::after {
    animation-delay: 1s;
  }
  .icon {
    color: $color-snow;
    width: 11rem;
    height: 11rem;
    padding: 0.5rem;
  }
}

@keyframes wave-animation {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}
